/*
Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/css.css to edit this template
*/
/* 
    Created on : 29/12/2023, 18:03:50
    Author     : felipe
*/

.capa-batismo{
    width: 100vw;
}

.div_selecao{
    width: 100vw;
    height: 30vh;

}

.sel_item{
    width: 33vw;
    color: #666;
}



.batismo_calendario{
    background-color: #004569;
    width: 100vw;
    height: 62.5vw;
    min-height: 470px;
    background-image: url('../../../img/batismo_calendario.png');
    background-repeat: no-repeat;
    background-size: contain;
}


.batismo_carrossel{
    width:100vw;
    
}


.batismo_card{
    width: 275px;
    
    background-color: transparent;
    border: none;
    
}