/*
Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/css.css to edit this template
*/
/* 
    Created on : 10/11/2023, 11:10:15
    Author     : felipe
*/

#tab-horario{
    width: 100vw;

}


.nav-horario .nav-link{
    color: #004569;
    text-align: center;
}

.nav-horario .nav-link:hover{
    font-weight: bold;
}

.nav-horario .nav-link:focus{
    font-weight: bold;
}

.col-horario{
    background-color: #004569;
}

.col-horario .span-horario{
    color: #fff;
    text-align: center;
}

.horario-conteudo img{
    width: 100%;
}

.encontros-grupos{
    background-color: #004569;
    width:100vw;
    color: #fff;
    text-align: center;
}

.encontros-grupos .encontros-carrossel{
    width: 380px;
}

.bordaBranca{
    border: 0.5px #fff solid;
}

.horario-estreito{
    background-color: #004569;
    color: white;
}



#div-dizimo{
    box-shadow: rgba(0,0,0,0.8) 0px 6px 16px;
    width: 19vw;
    position: fixed;
    z-index: 2;
    bottom: 2vh;
    right: 2vw;
    min-width: 150px;
}

#div-avisos{
    width: 100vw;
    height: 37.625vw;
    background-image: url('../../../img/aviso.png');
    background-repeat: no-repeat;
    background-size: contain;
   
}

.aviso-lista{
    width: 30vw;
    left: 60vw;
    top: 1vw;
    height: 30vw;
    position: relative;
}

.aviso-lista img{
    width: 30vw;
}

