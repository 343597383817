/*
Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/css.css to edit this template
*/
/* 
    Created on : 01/12/2023, 19:04:32
    Author     : felipe
*/

.listaAvisos{
    height: 270px;
    width: 95vw;
    overflow-x: scroll;
}



.listaAvisos img{
    height: 190px;
    object-fit: contain;
}

.addImagem{
    width: 40vw;
}


.post_detail{
    font-size: small;
}