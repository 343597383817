/*
Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/css.css to edit this template
*/
/* 
    Created on : 10/01/2024, 11:11:43
    Author     : felipe
*/

.form_batismo{
    width: 285px;
    
}

.listaBatismos{
    width:50vw;
    min-width: 280px;
}
