/*
Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/css.css to edit this template
*/
/* 
    Created on : 08/11/2023, 14:44:32
    Author     : felipe
*/

.elemento-footer {
    font-family: 'Roboto';
    font-size: large;
    color: white;
    background-color: #004569;
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
   
}

.coluna-footer{
    border-left: 1px white solid;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
    
}

.coluna-footer h4{
    padding-bottom: 20px;
}

.coluna-footer p{
    text-align: justify;
}



.limpo{
    text-decoration: none;
    color: white;
}

.limpo:hover{
    font-weight: bold;
}

.header{
    width: 100%;
    height: 100%;
    

    color: #004569;
}

.header img{
    width: 100vw;
}

.elemento-logo{
    height: 30vh;
    min-height: 150px;
    float: right;
}


.navbar-saopedro .nav-link{
    color: #004569;
    font-weight: bold;
    text-align: center;
    width: fit-content;
}

.navbar-saopedro .adlam{
    color: #004569;
}

.navbar-logo{
    height: 90px;
    width: auto;
}

