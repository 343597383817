/*
Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/css.css to edit this template
*/
/* 
    Created on : 20/11/2023, 15:13:36
    Author     : felipe
*/

.form-usuario{
    width: 50vw;
    min-width: 345px;
}

.listaUsuarios{
    width:50vw;
    min-width: 345px;
}

.btn-excluir{
    height: 30px;
    width: auto;
    padding: 0;
}