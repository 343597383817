/*
Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/css.css to edit this template
*/
/* 
    Created on : 20/11/2023, 13:32:34
    Author     : felipe
*/

.coluna{
    width: 200px;
}

img{
    width: 200px;
}

.erro{
    font-size: 7pt;
    color: red;
}