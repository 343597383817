.bordaAzul{
    border: 1px blue solid;
}

.adlam{
    font-family: 'ADLaM Display';
}

.roboto{
    font-family: 'Roboto';
}

.fonte-azul-5{
    color: #004569;
}

.centrado{
    text-align: center;
}

.justificado{
    text-align: justify;
}